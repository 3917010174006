#root {
    max-height: 100vh;
}
.mobile-nav--opened {
    @media (max-width: 767px) {
        transform: translate3d(0vw, 0, 0);
        overflow: hidden;
    }
}

.mobile-nav--closed {
    @media (max-width: 767px) {
        transform: translate3d(-100vw, 0, 0);
    }
}

.mainNavContainer {
    background-color: transparent;
}

#nav-burger span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
}
  
#nav-burger span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
}

#nav-burger span:nth-child(1), #nav-burger span:nth-child(2) {
    top: 0px;
}
  
#nav-burger span:nth-child(3), #nav-burger span:nth-child(4) {
    top: 11px;
}
#nav-burger span:nth-child(5), #nav-burger span:nth-child(6) {
    top: 22px;
}
  
#nav-burger.open span:nth-child(1),#nav-burger.open span:nth-child(6) {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}
  
#nav-burger.open span:nth-child(2),#nav-burger.open span:nth-child(5) {
-webkit-transform: rotate(-45deg);
-moz-transform: rotate(-45deg);
-o-transform: rotate(-45deg);
transform: rotate(-45deg);
}
  
#nav-burger.open span:nth-child(1) {
left: 5px;
top: 6px;
}
  
#nav-burger.open span:nth-child(2) {
left: calc(50% - 5px);
top: 6px;
}
  
#nav-burger.open span:nth-child(3) {
left: -50%;
opacity: 0;
}

#nav-burger.open span:nth-child(4) {
left: 100%;
opacity: 0;
}
  
#nav-burger.open span:nth-child(5) {
left: 5px;
top: 14px;
}

#nav-burger.open span:nth-child(6) {
left: calc(50% - 5px);
top: 14px;
}

#nav-burger.open {
    position: fixed;
}
#nav-burger:not(.open) {
    position: absolute;
}


  @font-face {
    font-family: "Amulya-Medium";
    font-style: normal;
    font-weight: normal;
    src: local('Amulya-Medium'), url(./fonts/amulya/Amulya-Medium.otf) format('opentype'), url(./fonts/amulya/Amulya-Medium.woff2) format('woff2'), url(./fonts/amulya/Amulya-Medium.woff) format('woff'), url(./fonts/amulya/Amulya-Medium.ttf) format('truetype');
  }

  @font-face {
    font-family: "Amulya-Bold";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Amulya-Bold'), url(./fonts/amulya/Amulya-Bold.otf) format('opentype'), url(./fonts/amulya/Amulya-Bold.woff2) format('woff2'), url(./fonts/amulya/Amulya-Bold.woff) format('woff'), url(./fonts/amulya/Amulya-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: "Amulya-MediumItalic";
    font-style: italic;
    font-weight: normal;
    src: local('Amulya-MediumItalic'), url(./fonts/amulya/Amulya-MediumItalic.otf) format('opentype'), url(./fonts/amulya/Amulya-MediumItalic.woff2) format('woff2'), url(./fonts/amulya/Amulya-MediumItalic.woff) format('woff'), url(./fonts/amulya/Amulya-MediumItalic.ttf) format('truetype');
  }

  @font-face {
    font-family: "Amulya-BoldItalic";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local('Amulya-BoldItalic'), url(./fonts/amulya/Amulya-BoldItalic.otf) format('opentype'), url(./fonts/amulya/Amulya-BoldItalic.woff2) format('woff2'), url(./fonts/amulya/Amulya-BoldItalic.woff) format('woff'), url(./fonts/amulya/Amulya-BoldItalic.ttf) format('truetype');
  }

#square0 {
    border-top-left-radius: 5px;
}

#square2{
    border-top-right-radius: 5px;
}

#square6 {
    border-bottom-left-radius: 5px;
}

#square8{
    border-bottom-right-radius: 5px;
}