body {
  margin: 0;
  font-family: "Amulya-Medium", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5D6996;
  /* padding-bottom: 50px; */
}

code {
  font-family: "Amulya-Medium", Arial, sans-serif;
}
